import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <div align="center" className="header">
        TUTNH
      </div>
      <div align="center">
        <p>
          
          The universe that never happend,<br></br>
          T.U.T.N.H.<br></br>
          that's what it means.<br></br> 
          this is where I will put a some of the things I make.<br></br> 
          <br></br>
          maybe someday I may be able to show some things which never came to
          happen.
        </p>
      </div>
    </Layout>
  )
}
