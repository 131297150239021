import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

import './layout.css'
const Layout = ({children}) => {
    return (
       <>
            <div className="layoutNavbar"> <Navbar /> </div> 
            <div className="layoutChild"> {children} </div>
            <div className="layoutFooter"> <Footer /> </div>
       </>
    )
}

export default Layout
