import React from 'react'

const Footer = () => {
    return (
      <footer>
        <center><a href="https://haloweave.com/">Friend's site</a></center>
        <a href="https://twitter.com/subbu_tutnh">
            <p style={{textAlign: 'center'}}>Twitter</p></a>
      </footer>
    )
}

export default Footer
