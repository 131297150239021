import React from 'react'
import {Link} from 'gatsby'

const Navbar = () => {
    return (
        <nav align="center">
            <ul style={{ listStyle: "none",margin: 0, padding: 0}}>
                <li style={{display: "inline",padding: 10}}><Link to= "/">Home</Link></li>
                <li style={{display: "inline",padding: 10}}><Link to= "/witchsKnight">WitchsKnight</Link></li>
            </ul>
        </nav>
    )
}

export default Navbar
